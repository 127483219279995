import React, { useState, useEffect } from "react";
import { collection, setDoc, doc, updateDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "./firebase";
import { signOut } from "firebase/auth";
import { auth } from "./firebase";

const Dashboard = ({ user }) => {
  const [records, setRecords] = useState([]);
  const [workType, setWorkType] = useState("現場");
  const [clockIn, setClockIn] = useState("09:00");
  const [clockOut, setClockOut] = useState("17:45");

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState((new Date().getMonth() + 1).toString().padStart(2, '0'));
  const [day, setDay] = useState(new Date().getDate().toString().padStart(2, '0'));

  const years = Array.from({ length: 20 }, (_, i) => (new Date().getFullYear() - 10 + i).toString());
  const months = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
  const days = Array.from({ length: new Date(year, month, 0).getDate() }, (_, i) => (i + 1).toString().padStart(2, '0'));

  const fetchRecords = async () => {
    const startDate = `${year}-${month}-01`;
    const endDate = `${year}-${month}-${new Date(year, month, 0).getDate()}`;

    const recordsRef = collection(db, "time_cards", user.uid, "records");
    const q = query(recordsRef, where("date", ">=", startDate), where("date", "<=", endDate));
    const snapshot = await getDocs(q);
    const recordsData = snapshot.docs.map(doc => doc.data());
    setRecords(recordsData);
  };

  useEffect(() => {
    fetchRecords();
  }, [user, year, month]);

  const handleClockIn = async () => {
    const date = `${year}-${month}-${day}`;
    await setDoc(doc(db, "time_cards", user.uid, "records", date), {
      date,
      workType,
      clockIn,
      clockOut: null
    }, { merge: true });
    fetchRecords();
  };

  const handleClockOut = async () => {
    const date = `${year}-${month}-${day}`;
    await updateDoc(doc(db, "time_cards", user.uid, "records", date), {
      clockOut
    });
    fetchRecords();
  };

  const handleLogout = async () => {
    await signOut(auth);
  };

  return (
    <div>
      <h1>Welcome, {user.email}</h1>
      <button onClick={handleLogout}>Logout</button>
      <div>
        <label>Year:</label>
        <select value={year} onChange={(e) => setYear(e.target.value)}>
          {years.map((yr) => (
            <option key={yr} value={yr}>{yr}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Month:</label>
        <select value={month} onChange={(e) => setMonth(e.target.value)}>
          {months.map((mn) => (
            <option key={mn} value={mn}>{mn}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Day:</label>
        <select value={day} onChange={(e) => setDay(e.target.value)}>
          {days.map((dy) => (
            <option key={dy} value={dy}>{dy}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Work Type:</label>
        <select value={workType} onChange={(e) => setWorkType(e.target.value)}>
          <option value="現場">現場</option>
          <option value="自社">自社</option>
          <option value="テレワーク">テレワーク</option>
        </select>
      </div>
      <div>
        <label>Clock In:</label>
        <input type="time" value={clockIn} onChange={(e) => setClockIn(e.target.value)} />
      </div>
      <div>
        <label>Clock Out:</label>
        <input type="time" value={clockOut} onChange={(e) => setClockOut(e.target.value)} />
      </div>
      <button onClick={handleClockIn}>Clock In</button>
      <button onClick={handleClockOut}>Clock Out</button>
      <h2>Records</h2>
      <ul>
        {records.map((record, index) => (
          <li key={index}>{record.date}: {record.workType} {record.clockIn} - {record.clockOut}</li>
        ))}
      </ul>
    </div>
  );
};

export default Dashboard;
